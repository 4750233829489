<template>
  <div style="position: relative; height: 600px; width: 100%;">
    <canvas id="ventasCategoria"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  data() {
    return {
      colors: ['#7BC0F7', '#3B8AD9', '#F18226', '#61737B', '#F18226',  '#A6B3B3'],
      months: [
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
        {id: 1, name: "enero"},
      ],
      dataSetItem: {
        label: null,
        data: [],
        backgroundColor: null
      },
    }
  },
  methods: {
    /* 
      En este metodo se va a contruir el dataset
      que se va a utilizar para generar la grafica.
    */
    createDatasets(ventasPorCategoria, categories) { 
      const dataSet = []
      
      categories.forEach((category, index) => {
        const dataItem = []
        ventasPorCategoria.forEach((month, i) => {
          dataItem.push(0)
          month.repuesta.forEach((resp) => {
            if (resp.categoria_producto === category.dato) {
              dataItem[i] = resp.total_ventas
            }
          })
        })
        // console.log("dataItem: ", dataItem);
        const ds = {
          label: category.dato,
          data: dataItem,
          backgroundColor: this.colors[index]
        }
        dataSet.push(ds)
      });
      this.createChart(dataSet)
    },
    createChart(dataSet) {
      const ctx = document.getElementById('ventasCategoria')
      ctx.height = 400
      const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: [
            "enero",
            "febrero",
            "marzo",
            "abril",
            "mayo",
            "junio",
            "julio",
            "agosto",
            "septiembre",
            "octubre",
            "noviembre",
            "diciembre"
          ],
          datasets: dataSet
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }]
          },
          title: {
            display: true,
            text: "VENTAS POR CATEGORIA",
            fontSize: 28,
            fontColor: '#2D4761',
          }
        }
      })
    }

  }
}
</script>